<template>
  <b-modal
    id="modal-create-questions-votes"
    centered
    size="lg"
    hide-footer
    @shown="setItem"
  >
    <template #modal-header>
      <language-selector-header :title="itemEdit.key?$t('votes.edit-question'):$t('votes.create-question')" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language"/>
    </template>

    <b-form @submit.prevent="submitForm">
      <b-form-group :label="$t('form-create-item.title')" label-for="question-title" class="mb-3">
        <b-form-input
          id="question-title"
          v-model="question[selectedLanguage]"
          class="my-50"
          :placeholder="$t('layouts.widget-name')"
        />
      </b-form-group>
      <div v-for="(item, index) in options" :key="index">
        <b-form-group class="mt-2">
          <label for="options">{{ $t('votes.option') }} {{ index + 1 }}</label>
          <b-form-input id="options" v-model="item[selectedLanguage]" aria-describedby="default-option" />
          <b-form-text v-if="toggleDefaultOption" id="default-option">
            <b-form-radio
              v-model="defaultOptionIndex"
              class="mt-50"
              name="some-radios"
              :value="index"
            >
              <small class="text-secondary">{{ $t('votes.default-option') }}</small>
            </b-form-radio>
          </b-form-text>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <b-button variant="primary" @click="addOption">
            <feather-icon icon="PlusCircleIcon" class="text-white" />
          </b-button>
          <b-button v-if="options.length > 2" variant="primary ml-2" @click="deleteOption">
            <feather-icon icon="DeleteIcon" class="text-white" />
          </b-button>
        </div>
        <b-form-group class="mb-0">
          <b-form-checkbox v-model="toggleDefaultOption" @input="defaultOptionSelector">
            <small class="text-secondary">{{ $t('votes.toggle-default-option') }} </small>
          </b-form-checkbox>
        </b-form-group>
      </div>
      <b-button
        variant="primary"
        class="float-right"
        type="submit"
        :disabled="(Object.values(question).length === 0 && Object.values(options).length === 0) || isSending"
      >
        <span
          v-if="isSending"
        ><b-spinner
           label="Sending..."
           variant="white"
           class="mr-50"
           small
         />
          {{ $t('backoffice.subscriptions.form.action.submit') }}
        </span>
        <span v-else>{{ $t('form.actions.save') }}</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue'


export default {
  name: 'CreateOptionsQuestionsVotesModal',
  mixins: [ToastNotificationsMixin],
  props: {
    itemEdit: { type: Object, default: () => {} },
    formKey: { type: String, required: true },
  },
  components: {
    LanguageSelectorHeader
  },
  data() {
    return {
      selectedLanguage: "",
      question: {},
      options: [{}, {}],
      defaultOptionIndex: -1,
      toggleDefaultOption: false,
      isSending: false,
      isEditing: false,
      indexDefaultOption: -1,
    };
  },
  methods: {
    setItem() {
      this.isEditing = false;
      if (this.itemEdit.key) {
        this.isEditing = true;
        this.question = { ...this.itemEdit.name };
        this.options = [...this.itemEdit.options];
        this.indexDefaultOption = this.itemEdit.options.findIndex((option) => option.defaultOption);
        if (this.indexDefaultOption > -1) {
          this.defaultOptionIndex = `${this.indexDefaultOption}`;
          this.toggleDefaultOption = true;
        }
      }
    },
    addOption() {
      this.options.push({});
    },
    deleteOption() {
      if (this.options.length > 2) {
        this.options.pop();
      }
    },
    closeModal() {
      this.question = {};
      this.options = [{}, {}];
      this.indexDefaultOption = -1;
      this.toggleDefaultOption = false;
      this.$bvModal.hide('modal-create-questions-votes');
    },
    defaultOptionSelector() {
      this.toggleDefaultOption
        ? this.defaultOptionIndex > 0
          ? this.defaultOptionIndex
          : (this.defaultOptionIndex = 0)
        : (this.defaultOptionIndex = -1);
    },
    addDefaultOption() {
      this.options[this.defaultOptionIndex].defaultOption = true;
    },
    submitForm() {
      this.itemEdit.key ? this.handleEditItem() : this.handleCreateItem();
    },
    async handleCreateItem() {
      this.isSending = true;
      if (this.defaultOptionIndex > -1) {
        this.addDefaultOption();
      }
      try {
        await this.$store.dispatch('createItem', {
          item: {
            customName: 'votes',
            itemType: 'formQuestions',
            requestConfig: {
              formKey: this.formKey,
              name: this.question,
              options: this.options,
              type: 'radio',
            },
          },
        });
        this.$emit('data');
        this.$emit('edited');
        this.closeModal();
        this.isSending = false;
        this.notifySuccess(this.$t('votes.edit-success'));
      } catch {
        this.notifyError(this.$t('backoffice.members.modals.error-disable-all'));
      }
    },
    async handleEditItem() {
      this.isSending = true;
      if (this.indexDefaultOption > -1) {
        delete this.options[this.indexDefaultOption].defaultOption;
      }
      if (this.defaultOptionIndex > -1) {
        this.addDefaultOption();
      }
      try {
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'formQuestions',
            customName: 'votes',
            requestConfig: {
              formKey: this.formKey,
              questionKey: this.itemEdit.key,
              name: this.question,
              options: this.options,
            },
          },
        });
        this.closeModal();
        this.isSending = false;

        this.notifySuccess(this.$t('votes.edit-success'));
      } catch {
        this.notifyError(this.$t('backoffice.members.modals.error-disable-all'));
      }
    },
  },
};
</script>

<style></style>
