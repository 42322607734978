<template>
  <div class="pb-1 d-block">
    <feather-icon
      v-if="isStaff"
      v-b-tooltip.hover.bottom
      icon="Edit2Icon"
      :title="$t('votes.edit')"
      size="18"
      class="text-primary edit-icon float-right"
      role="button"
      @click="editModal(item)"
    />
    <feather-icon
      v-if="isStaff"
      v-b-tooltip.hover.bottom
      :title="$t('unshare.tooltip')"
      icon="TrashIcon"
      size="18"
      class="text-primary edit-icon float-right mr-1"
      role="button"
      @click="deleteItem(item.key)"
    />
    <feather-icon
      v-if="isStaff"
      v-b-tooltip.hover.bottom
      icon="ActivityIcon"
      :title="$t('votes.results')"
      size="18"
      class="text-primary edit-icon float-right mr-1"
      role="button"
      @click="goResults(item.key)"
    />
    <h3 class="html-text-ellipsis-2 title">
      {{ item.name[currentLocale] || Object.values(item.name)[0] }}
    </h3>

    <span class="html-text-ellipsis-4 text-grey mb-2" v-html="item.description[currentLocale] || Object.values(item.description)[0]" />
    <div class="w-100 align-items-center mb-2 d-md-flex d-block flex-wrap">
      <p class="text-muted mb-0 mr-md-1">
        {{ $t('votes.total-questions') }}
        <span
          class="text-underline"
          @click="isQuestionsShown = true"
        >{{ item.questions.length }} {{ $t('votes.questions').toLowerCase() }}</span>
      </p>
      <b-button
        v-if="item.questions.length === 0 && !isQuestionsShown"
        size="sm"
        variant="link"
        class="small mr-md-25 px-0"
        @click="isQuestionsShown = true"
      >
        {{ $t('votes.add-questions') }}
        <b-icon-chevron-down variant="primary" />
      </b-button>
      <b-button
        v-else-if="item.questions.length > 0 && !isQuestionsShown"
        size="sm"
        variant="link"
        class="small mr-md-25 px-0"
        @click="isQuestionsShown = true"
      >
        {{ $t('votes.show-questions') }}
        <b-icon-chevron-down variant="primary" />
      </b-button>
      <b-button
        v-else
        size="sm"
        variant="link"
        class="small mr-md-25 px-0"
        @click="isQuestionsShown = false"
      >
        {{ $t('votes.hide-questions') }}
        <b-icon-chevron-up variant="primary" />
      </b-button>
    </div>
    <div>
      <b-container v-if="isStaff" class="text-center">
        <b-button
          v-if="onGoingVotation && item.questions.length > 0 && actualForm === item.key"
          variant="primary"
          class="m-50 votes-button vote-button"
          :disabled="actualQuestionIndex > item.questions.length - 1"
          @click="nextQuestion(actualQuestion == null ? item.questions[0].key : nextQuestionKey)"
        >
          <span
            v-if="actualQuestionIndex < item.questions.length"
          >{{ $t('votes.next-button', { question: actualQuestionIndex + 1 }) }}
          </span>
          <span v-else>{{ $t('votes.no-more-questions') }}</span>
        </b-button>
        <b-button
          v-if="!onGoingVotation || actualForm !== item.key"
          variant="success"
          class="m-50 vote-button"
          :disabled="item.questions.length === 0"
          @click="startVotation(item.key)"
        >
          {{ $t('votes.start-button') }}
        </b-button>
        <b-button
          v-else-if="onGoingVotation || actualForm === item.key"
          variant="outline-danger"
          class="m-50 vote-button"
          @click="finishVotation()"
        >
          {{ $t('votes.end-button') }}
        </b-button>
      </b-container>
      <div>
        <questions-votes
          v-if="isQuestionsShown"
          :actual-num="actualQuestionIndex"
          :actual="actualQuestion"
          :items="item"
          :on-going-votation="onGoingVotation"
          @data="data"
        />
      </div>
    </div>
    <edit-votes-modal v-if="Object.values(itemEdit).length > 0" :item="itemEdit" @close-modal="closeModal" />
    <unshare-modal v-model="isUnshareModalVisible" model-type="vote" @remove-item="handleRemoveItem" />
  </div>
</template>

<script>
import FaqAccordion from '@/views/apps/faqs/components/FaqAccordion.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import CreateVotesModal from '@/@core/components/modal/CreateVotesModal.vue';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import EditVotesModal from '@/@core/components/modal/EditVotesModal.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import QuestionsVotes from '@/views/votes/QuestionsVotes.vue';

export default {
  components: {
    FaqAccordion,
    FormTranslationTable,
    ListContainerWidget,
    WidgetActions,
    CreateVotesModal,
    EditVotesModal,
    UnshareModal,
    QuestionsVotes,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      form: [],
      searchInput: '',
      results: [],
      keyup: null,
      open: false,
      finished: false,
      onGoingVotation: false,
      totalAnswers: 0,
      itemEdit: {},
      isUnshareModalVisible: false,
      itemDelete: null,
      actualForm: null,
      actualQuestion: null,
      options: [{}, {}],
      question: {},
      actualQuestionIndex: 0,
      isQuestionsShown: false,
      selectedLanguage: this.currentLocale,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    nextQuestionKey() {
      const actualForms = this.item;
      if (this.actualQuestionIndex === actualForms.questions.length) {
        return;
      }

      return actualForms.questions[this.actualQuestionIndex].key;
    },
  },
  async created() {
    this.getActualForm();
    this.getActualQuestion();
    this.checkActualQuestion();
  },
  sockets: {
    onGoingVotation(data) {
      this.onGoingVotation = data;
      return data;
    },
    startedVote(data) {
      this.finished = false;
    },
    totalAnswers(data) {
      this.totalAnswers = data;
      return data;
    },
    actualQuestion(data) {
      this.actualQuestion = data;
      return data;
    },
    actualForm(data) {
      this.actualForm = data;
      return data;
    },
    finished(data) {
      this.actualQuestion = null;
      this.actualQuestionIndex = 0;
      this.finished = true;
      return data;
    },
    actualQuestionNumber(data) {
      this.actualQuestionIndex = data;
    },
  },
  methods: {
    goResults(key) {
      this.$router.push({ name: 'votesResults', params: { id: key } });
    },
    getActualQuestion() {
      this.$socket.emit('getActualQuestion', { communitySlug: this.collective.slug });
    },
    getActualForm() {
      this.$socket.emit('getActualForm', { communitySlug: this.collective.slug });
    },
    nextQuestion(questionKey) {
      this.$socket.emit('nextQuestion', { communitySlug: this.collective.slug, questionKey });
    },
    startVotation(formKey) {
      this.$socket.emit('startVote', { communitySlug: this.collective.slug, formKey });
    },
    finishVotation() {
      this.finished = true;
      this.$socket.emit('finalCheck', this.collective.slug);
      setTimeout(() => {this.$socket.emit('finishVote', { communitySlug: this.collective.slug })}, 3000)
    },
    checkActualQuestion() {
      this.$socket.emit('getActualQuestionNumber', { communitySlug: this.collective.slug });
    },
    deleteItem(key) {
      this.isUnshareModalVisible = true;
      this.itemDelete = key;
    },
    editModal(item) {
      this.itemEdit = { ...item };
      this.$nextTick(function () {
        this.$bvModal.show('modal-edit-votes');
      });
    },
    closeModal() {
      this.itemEdit = {};
    },
    data() {
      this.$emit('data');
    },
    async handleRemoveItem(response) {
      const temo = {
        key: this.itemDelete,
      };
      if (response === true) {
        try {
          await this.$store.dispatch('unshareProject', { item: temo, type: 'forms', tableName: 'votes' });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.success', { item: this.$t('unshare.singular-items.vote') }),
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', { item: this.$t('unshare.singular-items.vote') }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include';

@include media-breakpoint-down(sm) {
  .vote-button {
    min-width: 70%;
  }
}
@include media-breakpoint-up(sm) {
  .vote-button {
    min-width: 185px;
  }
}
</style>
