<template>
  <div
    class="flex-column"
    :class="isStaff || canAdd ? 'd-flex mt-3' : finished ? '' : ''"
  >
    <div class="w-100 mb-2 float-left">
      <div
        class="align-items-center float-left"
        :class="isStaff || canAdd ? 'd-flex' : 'd-none'"
      >
        <h4 class="m-0 mb-2">
          {{ $t("votes.questions") }}
        <div class="staff-vote-alert">
          If you are a staff you can't vote.
        </div>
        </h4>
      </div>

      <!-- New question to vote -->
      <b-button
        v-if="isStaff || (canAdd && !onGoingVotation)"
        id="create-voting-question"
        variant="outline-primary"
        size="sm"
        class="text-primary d-flex align-items-center float-right create-item"
        @click="openModal"
      >
        <p class="m-0 mr-25">
          {{ $t("votes.new-question") }}
        </p>
        <feather-icon icon="PlusIcon" variant="primary" size="25" />
      </b-button>
    </div>
    <div>
      <!-- placeholder final votación -->
      <div v-if="finished && !isStaff">
        <b-row class="horizontal-placeholder">
          <b-col cols="12">
            <b-img
              class="my-0 mb-1 image-success"
              :src="finishVotesPlaceholder"
              :alt="$t('votes.end')"
            />
          </b-col>
          <b-col cols="12" style="padding: 0px !important">
            <p class="text-primary">
              {{ $t("votes.end") }}
            </p>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="items.questions && items.questions.length > 0">
        <div v-if="isStaff || canAdd" ref="container" class="w-100">
          <div
            v-for="(item, index) in items.questions"
            :key="index"
            no-body
            class="x-scroll-element mb-2"
          >
            <div class="pb-2 d-block">
              <feather-icon
                v-if="!onGoingVotation"
                icon="Edit2Icon"
                size="18"
                class="text-primary edit-icon float-right"
                role="button"
                @click="editModal(item)"
              />
              <feather-icon
                v-if="!onGoingVotation"
                icon="TrashIcon"
                size="18"
                class="text-primary edit-icon float-right mr-1"
                role="button"
                @click="deleteItem(item.key)"
              />
              <div class="d-flex mb-1">
                <div
                  class="rounded-circle overflow-hidden d-flex align-items-center justify-content-center number"
                  :class="
                    actual !== null && actual.key === item.key
                      ? 'background-primary-color'
                      : 'background-light-color'
                  "
                >
                  <h4
                    class="m-0"
                    :class="
                      actual !== null && actual.key === item.key
                        ? 'text-white'
                        : 'text-primary'
                    "
                  >
                    {{ index + 1 }}
                  </h4>
                </div>
                <div class="d-flex flex-column pl-1">
                  <h4
                    class="title"
                    :class="
                      actual !== null && actual.key === item.key
                        ? 'text-primary'
                        : ''
                    "
                  >
                    {{
                      item.name[currentLocale] || Object.values(item.name)[0]
                    }}
                  </h4>
                  <span
                    v-if="index + 1 === actualQuestion && respons"
                    class="text-muted"
                  >
                    {{ $t("votes.made") }}:
                    {{ totalAnswer[actualQuestion] }}</span
                  >
                </div>
              </div>
              <div class="d-flex">
                <div
                  class="line-container d-flex align-items-center justify-content-center"
                  :style="
                    index + 1 === actualQuestion && respons
                      ? { 'margin-top': '-20px' }
                      : ''
                  "
                >
                  <div class="line h-100 background-light-color" />
                </div>
                <b-list-group v-if="item.options" class="ml-1 w-100">
                  <b-list-group-item
                    v-for="(option, index2) in item.options"
                    :key="index2"
                    class="d-flex align-items-center option"
                  >
                    <div class="rounded-circle border small-circle mr-1" />
                    <p
                      class="mb-0"
                      :class="
                        actual !== null && actual.key === item.key
                          ? 'font-weight-600'
                          : ''
                      "
                    >
                      {{ option[currentLocale] || Object.values(option)[0] }}
                    </p>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="item != null" ref="container" class="w-100">
          <b-card
            v-if="canVote"
            no-body
            class="x-scroll-element mx-auto mb-3 p-0 card-standard"
          >
            <b-card-body v-if="!voted" class="pb-2 d-block">
              <!--<p>{{ $t('votes.singular') }} {{ actualNum }}/{{ items.questions.length }}</p>-->
              <p>{{ $t("votes.question-upper") }} {{ actualNum }}</p>
              <h4 class="titulo">
                {{ item.name[currentLocale] || Object.values(item.name)[0] }}
              </h4>
              <div v-if="item.options">
                <b-card
                  v-for="(option, index2) in item.options"
                  :key="index2"
                  class="text-center option answer"
                  @click="
                    voteAnswer(
                      option[0][currentLocale] || Object.values(option[0])[0]
                    )
                  "
                >
                  {{ option[0][currentLocale] || Object.values(option[0])[0] }}
                </b-card>
              </div>
            </b-card-body>
            <b-card-body v-else class="pb-2 d-block">
              <!--<p>{{ $t('votes.singular') }} {{ actualNum }}/{{ items.questions.length }}</p>-->
              <p>{{ $t("votes.question-upper") }} {{ actualNum }}</p>
              <h4 class="titulo">
                {{ item.name[currentLocale] || Object.values(item.name)[0] }}
              </h4>

              <div v-if="item.options">
                <b-card class="text-center">
                  {{ $t("votes.thanks") }}
                </b-card>
              </div>
            </b-card-body>
          </b-card>
          <b-card
            v-else
            no-body
            class="x-scroll-element mx-auto mb-3 p-0 card-standard"
          >
            <b-card-body v-if="!voted" class="pb-2 d-block">
              <!--<p>{{ $t('votes.singular') }} {{ actualNum }}/{{ items.questions.length }}</p>-->
              <p>{{ $t("votes.question-upper") }} {{ actualNum }}</p>
              <h4 class="titulo">
                {{ item.name[currentLocale] || Object.values(item.name)[0] }}
              </h4>
              <div>
                <b-card v-if="!canVote" class="text-center option no-vote">
                  {{ $t("votes.cant-vote") }}
                </b-card>
              </div>
            </b-card-body>
          </b-card>
        </div>
        <!-- placeholder entre votación -->
        <div v-else>
          <b-row class="horizontal-placeholder">
            <b-col cols="12" style="padding: 0px !important">
              <b-card
                class="x-scroll-element mx-auto py-3 mb-2 p-0 card-standard"
              >
                <h1 class="text-center">
                  {{
                    items.name[currentLocale] || Object.values(items.name)[0]
                  }}
                </h1>
                <h5 class="text-center h4 text-secondary mb-2">
                  {{
                    items.description[currentLocale] ||
                    Object.values(items.description)[0]
                  }}
                </h5>
                <div>
                  <div>
                    <b-img
                      :src="waitingPlaceholder"
                      class="my-0 mt-50 mb-75 wait-placeholder"
                    />
                    <p class="text-muted h6 mb-50">
                      {{ $t("votes.preparation") }}
                    </p>
                    <p class="text-primary small my-0 mb-0">
                      {{ $t("votes.waiting-members") }}
                    </p>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-else>
        <b-row class="horizontal-placeholder">
          <b-col cols="12">
            <b-img
              :src="questionsPlaceholder"
              :alt="$t('votes.end')"
              width="100px"
            />
          </b-col>
          <b-col cols="12" style="padding: 0px !important">
            <p class="text-primary">
              {{
                $t("backoffice.feedback.placeholder-text", {
                  item: $t("votes.questions"),
                })
              }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
    <create-options-questions-votes-modal
      :form-key="this.items.key"
      :item-edit="itemEdit"
      @edited="$emit('data')"
    />
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="question"
      @remove-item="handleRemoveItem"
    />
  </div>
</template>
<script>
import FaqAccordion from "@/views/apps/faqs/components/FaqAccordion.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import FormTranslationTable from "@core/components/form-translation-table/FormTranslationTable.vue";
import VoteSuccess from "@/assets/images/placeholders/light/vote-success.svg";
import urnaPlaceholder from "@/assets/images/placeholders/light/vote-success.svg";
import QuestionPlaceholder from "@/assets/images/placeholders/light/Preguntas.svg";
import WaitingVote from "@/assets/images/placeholders/light/waiting-clock.svg";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import CreateVotesModal from "@/@core/components/modal/CreateVotesModal.vue";
import UnshareModal from "@/@core/components/modal/UnshareModal.vue";
import EditVotesModal from "@/@core/components/modal/EditVotesModal.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import CreateOptionsQuestionsVotesModal from "./modals/CreateOptionsQuestionsVotesModal.vue";
import { isDef } from "@vueuse/shared";
import { checkPermissions } from "@/@core/utils/roles-utils";

export default {
  name: "QuestionVotes",
  components: {
    FaqAccordion,
    FormTranslationTable,
    ListContainerWidget,
    WidgetActions,
    CreateVotesModal,
    EditVotesModal,
    UnshareModal,
    CreateOptionsQuestionsVotesModal,
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: "visible",
  },
  props: {
    items: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => null,
    },
    actual: {
      type: Object,
      default: () => {},
    },
    finished: {
      type: Boolean,
      default: false,
    },
    onGoingVotation: {
      type: Boolean,
      default: false,
    },
    actualNum: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      searchInput: "",
      results: [],
      keyup: null,
      open: false,
      itemEdit: {},
      isUnshareModalVisible: false,
      itemDelete: null,
      voted: false,
      before: null,
      formkey: null,
      form: [],
      last: null,
      totalAnswer: [],
      actualQuestion: 0,
      respons: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    finishVotesPlaceholder() {
      return VoteSuccess;
    },
    votesPlaceholder() {
      return VoteSuccess;
    },
    waitingPlaceholder() {
      return WaitingVote;
    },
    questionsPlaceholder() {
      return QuestionPlaceholder;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    canAdd() {
      return checkPermissions(
        "create",
        "vote",
        this.loggedMemberRoles,
        this.collective,
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    itemsData() {
      return this.$store.getters.votes;
    },
    defaultResponse() {
      if (this.item == null) {
        return null;
      }

      let item = this.items.questions[this.actualQuestion - 2];

      if (this.actualQuestion - 2 < 0) {
        item = this.items.questions[0];
      }

      for (const row of item.options) {
        if (row.defaultOption != null) {
          return row[this.currentLocale] || Object.values(row)[0];
        }
      }
    },
    defaultResponseLast() {
      if (this.item == null) {
        return null;
      }

      let item = this.items.questions[this.actualQuestion - 2];

      item = this.items.questions[this.items.questions.length - 1];

      for (const row of item.options) {
        if (row.defaultOption != null) {
          return row[this.currentLocale] || Object.values(row)[0];
        }
      }
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    canVote() {
      const checkPermissionsBool = checkPermissions(
          "create",
          "vote",
          this.loggedMemberRoles,
          this.collective,
          "answer"
        );
      //console.log('checkPermissionsBool', checkPermissionsBool);
      return checkPermissionsBool;
    },
  },
  sockets: {
    actualQuestionNumber(data) {
      this.actualQuestion = data;
    },
    totalAnswers(data) {
      this.respons = false;
      this.totalAnswer[this.actualQuestion] = data;
      this.respons = true;
      return data;
    },
    votedOk(data) {
      this.voted = true;
    },
    check(data) {
      this.actualQuestion = this.items.questions.length - 1 + 2;
      if (
        this.voted === false &&
        this.defaultResponseLast != null &&
        !this.isStaff &&
        this.canVote &&
        this.actualQuestion > 0
      ) {
        setTimeout(() => {
          this.voteAnswer(this.defaultResponseLast, true);
        }, 1000);
      }
    },
    nextQuestion(data) {
      this.$socket.emit("getActualQuestion", {
        communitySlug: this.$store.getters.currentCollective.slug,
      });
      this.$socket.emit("getActualQuestionNumber", {
        communitySlug: this.$store.getters.currentCollective.slug,
      });
      this.getIfAnswered();
      if (
        this.voted === false &&
        this.defaultResponse != null &&
        !this.isStaff &&
        this.canVote &&
        this.actualQuestion > 0
      ) {
        setTimeout(() => {
          this.voteBeforeAnswer(this.defaultResponse, true);
        }, 1000);
      }
      this.totalAnswer[this.actualQuestion] = 0;
      this.voted = false;
    },
    answered(data) {
      this.voted = data;
    },
    beforeQuestion(data) {
      this.before = data;
    },
  },
  created() {
    this.getIfAnswered();
    this.$socket.emit("getActualForm", {
      communitySlug: this.$store.getters.currentCollective.slug,
    });
    this.$socket.emit("getActualQuestion", {
      communitySlug: this.$store.getters.currentCollective.slug,
    });
    this.$socket.emit("getActualQuestionNumber", {
      communitySlug: this.$store.getters.currentCollective.slug,
    });
  },
  beforeUpdate() {
    this.last = this.item;
  },
  methods: {
    getIfAnswered() {
      this.$socket.emit("getIfAnswered", {
        communitySlug: this.$store.getters.currentCollective.slug,
        memberKey: this.$store.getters.loggedMember.key,
      });
    },
    voteAnswer(response, isDefault = false) {
      if (
        !checkPermissions(
          "create",
          "vote",
          this.loggedMemberRoles,
          this.collective,
          "answer"
        )
      ) {
        return;
      }
      const memberKeys = [
        this.$store.getters.loggedMember.key,
        ...this.$store.getters.loggedMember.connections,
      ];
      this.voted = true;

      let questionKey = this.item?.key;

      if (isDefault) {
        this.voted = false;
      }

      this.$socket.emit("answerQuestion", {
        communitySlug: this.$store.getters.currentCollective.slug,
        questionKey,
        memberKey: memberKeys,
        response,
        isDefault,
      });
    },
    voteBeforeAnswer(response, isDefault = false) {
      if (
        !checkPermissions(
          "create",
          "vote",
          this.loggedMemberRoles,
          this.collective,
          "answer"
        )
      ) {
        return;
      }
      const memberKeys = [
        this.$store.getters.loggedMember.key,
        ...this.$store.getters.loggedMember.connections,
      ];
      this.voted = true;

      let questionKey = this.before?.key;

      if (isDefault) {
        this.voted = false;
      }

      this.$socket.emit("answerBeforeQuestion", {
        communitySlug: this.$store.getters.currentCollective.slug,
        questionKey,
        memberKey: memberKeys,
        response,
        isDefault,
      });
    },
    deleteItem(key) {
      this.isUnshareModalVisible = true;
      this.itemDelete = key;
    },
    editModal(item) {
      this.itemEdit = { ...item };
      this.$bvModal.show("modal-create-questions-votes");
    },
    resetInputs() {
      this.$bvModal.show("modal-create-votes");
    },
    openModal() {
      this.itemEdit = {};
      this.$bvModal.show("modal-create-questions-votes");
    },
    async handleRemoveItem(response) {
      const temo = {
        key: this.itemDelete,
        tableName: "votes",
      };
      if (response === true) {
        try {
          await this.$store.dispatch("deleteItems", {
            item: temo,
            type: "FormQuestions ",
            forceUpdate: true,
          });
          this.$emit("data");
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.success", {
                item: this.$t("unshare.singular-items.question"),
              }),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } catch (error) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.error", {
                item: this.$t("unshare.singular-items.question"),
              }),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";

.modal-logo {
  max-width: 16rem;
  min-width: 10rem;
}
.answer {
  background-color: #f0f0f5;
  color: black !important;
}
.no-vote {
  background-color: rgba(184, 40, 40, 0.721);
  color: white !important;
}
.answer :hover {
  background-color: lighten($primary, 30%);
  color: white !important;
}
.card-standard {
  width: 95% !important;
}
.card-standard h4 {
  -webkit-line-clamp: inherit !important;
  -webkit-box-orient: inherit !important;
  overflow: auto !important;
  text-overflow: unset !important;
}
.option {
  overflow: hidden;
}
.wait-placeholder {
  width: 140px;
}
.number {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
}
.line-container {
  min-width: 2.5rem;
}
.line {
  min-width: 0.2rem;
}
.small-circle {
  height: 0.9rem;
  width: 0.9rem;
  min-width: 0.9rem;
}
.image-success {
  min-width: 250px !important;
  width: 250px !important;
}
.option:hover {
  .small-circle {
    background-color: $primary;
    border: none !important;
  }
}

.staff-vote-alert {
  font-size: x-small;
  color: lightgrey;
  margin-top: 6px;
}
</style>
